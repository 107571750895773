import React from "react";
import Layout from "../components/layout";

import PrivacyPolicyBody from '../../assets/appInfo/PrivacyPolicy';

const PrivacyPolicy = () => {
  return (
    <Layout title="プライバシーポリシー">
      <p className="text-primary" style={{ whiteSpace: 'pre-wrap', fontFamily: 'Noto Sans JP' }}>{PrivacyPolicyBody}</p>
    </Layout>
  );
};

export default PrivacyPolicy;
