import appConfig from '../../config';

const PrivacyPolicy = `
${appConfig.appName} プライバシーポリシー

${appConfig.appName}（以下「本サービス」）は、クライミングの活動記録を行うアプリです。
${appConfig.servicer}（以下「運営者」）は、本サービスの利用者（以下「ユーザー」）に関する個人情報を含んだ情報（以下「ユーザー情報」という）の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」という）を定めます。

１. 取得するユーザー情報と目的

・ユーザー情報  ユーザ識別ID
 取得方法  アプリケーションによる自動取得
 利用者情報の利用目的  本サービス提供の際にお客様を識別するため

・ユーザー情報  メールアドレスおよびパスワード
 取得方法  お客様による入力
 利用者情報の利用目的 本サービスのユーザ管理のため

２. 通知・公表または同意取得の方法・利用者関与の方法
（１）通知・公表
運営者は、本ポリシーに関する通知・公表は本サービス内に掲載する方法で行います。
（２）同意取得の方法
同意の取得は、本サービスの登録時に取得する方法で行います。
（３）利用者関与の方法
ユーザー情報の取得は、本サービス内でアカウント削除により中止することができます。

３．第三者提供・外部モジュール
（１）第三者提供
運営者は、取得したユーザー情報について、以下の場合第三者に提供することがあります。
・統計的なデータなどユーザーを識別することができない状態で提供する場合
・法令に基づき開示・提供を求められた場合

（２）外部モジュール
運営者は、本サービスの提供にあたり以下の外部モジュールを利用しています。
このような外部モジュールのサービスプロバイダーは、本サービスから送信されるユーザー情報等を収集するものとします。
・Google Firebase
Google Firebaseの利用規約・プライバシーポリシーについてはGoogle Firebase、Googleのホームページでご確認ください。
公式サイト https://firebase.google.com/
プライバシーポリシー https://www.google.com/intl/ja/policies/privacy/

４．お問い合わせ
本ポリシーに関する、ご意見、ご質問、苦情の申出その他ユーザー情報の取扱いに関するお問い合わせは、以下の窓口にご連絡ください。

「問い合わせ窓口」はこちら
${appConfig.infoMail}
※(at) は @ に置き換えて下さい。

５．改定
運営者は、必要に応じて本ポリシーを改定するものとします。
改定後の内容は本サービス内にてご確認いただくことが可能です。
運営者が独自の判断で本ポリシーに重要な変更を加える場合は、本サービス内でお知らせします。本ポリシーへの変更が発効した後に本サービスの利用を継続する場合、ユーザーは、改定後の本プライバシーポリシーに拘束されることに同意するものとします。

６．制定日・改定日
制定：2021年1月22日
改定：2022年5月20日

`;

export default PrivacyPolicy;
